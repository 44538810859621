import gql from 'graphql-tag';

export const updateDirection = gql`
  mutation updateDirection($_id: ID!, $index: Int, $newAddressVar: NewAddress!) {
    updateDirection(_id: $_id, index: $index, newAddressVar: $newAddressVar) {
      _id
      email
      profile {
        alias
        firstName
        lastName
        phone
        mobile
        basket {
          _id
          ean
          units
          book {
            priceWithDiscount
            _id
            title
          }
        }
        areaOfInterest
        shopping_default
        shoppingAddress {
          name
          address
          route
          streetNumber
          floor
          postalCode
          locality
          province
          country
          comment
          deliverTo
          isOutUE
          countryCode
          location {
            lat
            lng
          }
          cif
          businessName
        }
      }
    }
  }
`;

export const updateBillingData = gql`
  mutation updateBillingData($_id: ID!, $index: Int, $newAddressVar: NewAddress!) {
    updateBillingData(_id: $_id, index: $index, newAddressVar: $newAddressVar) {
      _id
      email
      profile {
        alias
        firstName
        lastName
        phone
        mobile
        basket {
          _id
          ean
          units
          book {
            priceWithDiscount
            _id
            title
          }
        }
        areaOfInterest
        shopping_default
        shoppingAddress {
          name
          address
          route
          streetNumber
          floor
          postalCode
          locality
          province
          country
          comment
          deliverTo
          isOutUE
          countryCode
          location {
            lat
            lng
          }
          cif
          businessName
        }
        billing_default
        billingData {
          address
          route
          streetNumber
          floor
          postalCode
          locality
          province
          country
          countryCode
          cif
          businessName
        }
      }
    }
  }
`;

export const rememberPassword = gql`
  mutation rememberPassword($email: String!) {
    rememberPassword(email: $email) {
      OK
    }
  }
`;

export const changePassword = gql`
  mutation changePassword($password: String, $confirmPassword: String) {
    changePassword(password: $password, confirmPassword: $confirmPassword) {
      OK
      message
    }
  }
`;

export const setAreaOfInterest = gql`
  mutation setAreaOfInterest($_id: ID!, $numberAreaOfInterest: Int) {
    setAreaOfInterest(_id: $_id, numberAreaOfInterest: $numberAreaOfInterest) {
      _id
      email
      profile {
        areaOfInterest
      }
    }
  }
`;

export const subscribeToNewsletter = gql`
  mutation subscribeToNewsletter(
    $_id: ID
    $email: String!
    $areaOfInterest: [Int]
    $active: Boolean
  ) {
    subscribeToNewsletter(
      _id: $_id
      email: $email
      areaOfInterest: $areaOfInterest
      active: $active
    ) {
      _id
      email
      profile {
        newsletter {
          areaOfInterest
        }
      }
    }
  }
`;
export const updateUser = gql`
  mutation updateUser($_id: ID!, $newUserVar: UserNew!) {
    updateUser(_id: $_id, newUserVar: $newUserVar) {
      _id
      email
      profile {
        alias
        firstName
        lastName
        phone
        mobile
        basket {
          _id
          ean
          units
          book {
            priceWithDiscount
            _id
            title
          }
        }
      }
    }
  }
`;

export const deleteUser = gql`
  mutation deleteUser($_id: ID!) {
    deleteUser(_id: $_id) {
      OK
      error
    }
  }
`;

export const updateOrderPaymentAfterStripeFlowMutation = gql`
  mutation updateOrderPaymentAfterStripeFlow(
    $orderId: String!
    $uncompleted: Boolean
    $stripePaymentIntentId: String
  ) {
    updateOrderPaymentAfterStripeFlow(
      orderId: $orderId
      uncompleted: $uncompleted
      stripePaymentIntentId: $stripePaymentIntentId
    ) {
      OK
    }
  }
`;

export const deleteAddress = gql`
  mutation deleteAddress($_id: ID!, $index: Int!) {
    deleteAddress(_id: $_id, index: $index) {
      _id
      email
      profile {
        alias
        firstName
        lastName
        areaOfInterest
        newsletter {
          areaOfInterest
        }
        basket {
          _id
          ean
          units
          book {
            priceWithDiscount
            product_type
            titleFriendly
            _id
            title
          }
        }
        shopping_default
        shoppingAddress {
          name
          address
          route
          streetNumber
          floor
          postalCode
          locality
          province
          country
          comment
          countryCode
          deliverTo
          isOutUE
          location {
            lat
            lng
          }
          cif
          businessName
        }
      }
    }
  }
`;

export const deleteBillingData = gql`
  mutation deleteBillingData($_id: ID!, $index: Int!) {
    deleteBillingData(_id: $_id, index: $index) {
      _id
      email
      profile {
        alias
        firstName
        lastName
        areaOfInterest
        newsletter {
          areaOfInterest
        }
        basket {
          _id
          ean
          units
          book {
            priceWithDiscount
            product_type
            titleFriendly
            _id
            title
          }
        }
        shopping_default
        shoppingAddress {
          name
          address
          route
          streetNumber
          floor
          postalCode
          locality
          province
          country
          comment
          countryCode
          deliverTo
          isOutUE
          location {
            lat
            lng
          }
          cif
          businessName
        }
        billing_default
        billingData {
          address
          route
          streetNumber
          floor
          postalCode
          locality
          province
          country
          countryCode
          cif
          businessName
        }
      }
    }
  }
`;

export const addToBasket = gql`
  mutation addToBasket($idUser: ID!, $idProduct: String!, $ean: String!, $units: Int) {
    addToBasket(idUser: $idUser, idProduct: $idProduct, ean: $ean, units: $units) {
      _id
      profile {
        basket {
          _id
          ean
          units
          book {
            lastBuyPrice
            exchangeRate
            buyExpenses
            priceWithDiscount
            product_type
            titleFriendly
            _id
            title
            mainImg
            brand {
              name
            }
          }
        }
      }
    }
  }
`;

export const modifyToBasket = gql`
  mutation modifyToBasket($idUser: ID!, $idProduct: String!, $operation: Boolean) {
    modifyToBasket(idUser: $idUser, idProduct: $idProduct, operation: $operation) {
      _id
      profile {
        basket {
          _id
          ean
          units
          product_type
          book {
            _id
            ean
            priceWithDiscount
            titleFriendly
            reposition_level
            product_type
            stock_available
            availability {
              OK
              msg
              status
            }
            title
            weight
            dimensions {
              depth
              width
              height
            }
            prices {
              sale
            }
            vat {
              sale
            }
            mainImg
          }
        }
      }
    }
  }
`;

export const removeToBasket = gql`
  mutation removeToBasket($idUser: ID!, $idProduct: String!) {
    removeToBasket(idUser: $idUser, idProduct: $idProduct) {
      _id
      profile {
        basket {
          _id
          ean
          units
          book {
            _id
            ean
            priceWithDiscount
            titleFriendly
            reposition_level
            product_type
            stock_available
            availability {
              OK
              msg
              status
            }
            title
            weight
            dimensions {
              depth
              width
              height
            }
            prices {
              sale
            }
            vat {
              sale
            }
            mainImg
          }
        }
      }
    }
  }
`;

export const newOrder = gql`
  mutation newOrder($newOrderVar: OrderNew!) {
    newOrder(newOrderVar: $newOrderVar) {
      _id
      order
      userId
      amountIVA
      orderSequence
      amountWithoutIVA
      deliveryAddress {
        name
        cif
        address
        route
        streetNumber
        floor
        postalCode
        locality
        province
        country
        comment
        countryCode
        isOutUE
      }
      transport
      amountTransport
      amount
      methodPayment
      fullNameUser
      fullNameUserClean
      legalNameFiscalData
      fiscalDoc
      state_order
      amountDiscount
      discountIdCode
      discountIdCodeWork
      confirmDate
      autorizationCode
      responseCode
      otherTaxes {
        amount
        description
        name
      }
      order_multiple
      giftCardCode
      errorCode
      deleted
      items {
        _id
        ean
        units
        priceSign
        priceSignDeal
        itemState
        canceledDate
        book {
          priceWithDiscount
          lastBuyPrice
          exchangeRate
          buyExpenses
          _id
          id
          title
          prices {
            sale
          }
          vat {
            sale
          }
        }
      }
      createdAt
    }
  }
`;

export const newEbookOrder = gql`
  mutation newEbookOrder($newOrderVar: OrderNew!) {
    newEbookOrder(newOrderVar: $newOrderVar) {
      _id
      order
      userId
      amountIVA
      orderSequence
      amountWithoutIVA
      amount
      methodPayment
      fullNameUser
      fullNameUserClean
      legalNameFiscalData
      fiscalDoc
      state_order
      amountDiscount
      discountIdCode
      discountIdCodeWork
      confirmDate
      autorizationCode
      responseCode
      otherTaxes {
        amount
        description
        name
      }
      order_multiple
      giftCardCode
      errorCode
      deleted
      items {
        _id
        ean
        units
        priceSign
        priceSignDeal
        itemState
        canceledDate
        book {
          priceWithDiscount
          lastBuyPrice
          exchangeRate
          buyExpenses
          _id
          id
          title
          prices {
            sale
          }
          vat {
            sale
          }
        }
      }
      createdAt
    }
  }
`;

export const newPayment = gql`
  mutation createPayment(
    $method: String
    $items: [OrderItemsNew]!
    $total: Float!
    $token: String
    $email: String
    $coupon: String
    $order_id: ID
    $state_order: String
    $orderSequence: Int!
    $amountTransport: Float!
    $isEbookCart: Boolean
    $paymentIntentId: String
  ) {
    createPayment(
      method: $method
      items: $items
      total: $total
      token: $token
      email: $email
      coupon: $coupon
      order_id: $order_id
      state_order: $state_order
      orderSequence: $orderSequence
      amountTransport: $amountTransport
      isEbookCart: $isEbookCart
      paymentIntentId: $paymentIntentId
    ) {
      id
      status
      errorCode
      outcome {
        network_status
        reason
        risk_level
        seller_message
        type
      }
      payer {
        payment_method
      }
      links {
        href
        method
      }
      redirect_urls
    }
  }
`;

export const executePayment = gql`
  mutation executePayment($paymentId: String, $payerID: String) {
    executePayment(paymentId: $paymentId, payerID: $payerID) {
      id
    }
  }
`;

export const get3DToken = gql`
  mutation get3DToken {
    get3DToken {
      token
    }
  }
`;

export const saveRefundRequest = gql`
  mutation saveRefundRequest($data: RefundData!) {
    saveRefundRequest(data: $data) {
      OK
    }
  }
`;

export const emptyBasket = gql`
  mutation emptyBasket($idUser: ID!) {
    emptyBasket(idUser: $idUser) {
      _id
      profile {
        basket {
          _id
          ean
          units
          book {
            # priceWithDiscount
            _id
            id
            title
            prices {
              sale
            }
            vat {
              sale
            }
          }
        }
      }
    }
  }
`;

// export const signUp = gql`
//   mutation signUp(
//     $email: String!
//     $password: String!
//     $profile: UserProfileNew
//   ) {
//     signUp(email: $email, password: $password, profile: $profile) {
//       token
//       OK
//       error
//       me
//     }
//   }
// `;

export const login = gql`
  mutation login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      token
      OK
      error
      me {
        _id
        email
        profile {
          alias
          firstName
          lastName
          basket {
            _id
            ean
            units
            book {
              lastBuyPrice
              exchangeRate
              buyExpenses
              priceWithDiscount
              product_type
              titleFriendly
              _id
              title
              mainImg
              brand {
                name
              }
            }
          }
        }
      }
    }
  }
`;

export const insertClick = gql`
  mutation insertClick($eanProduct: String!) {
    insertClick(eanProduct: $eanProduct) {
      _id
    }
  }
`;

export const insertClickID = gql`
  mutation insertClickID($idProduct: String!) {
    insertClickID(idProduct: $idProduct) {
      _id
    }
  }
`;

export const insertRecentView = gql`
  mutation insertRecentView($eanProduct: String, $userId: String) {
    insertRecentView(eanProduct: $eanProduct, userId: $userId) {
      _id
      lastViews {
        ean
        dateAdded
      }
    }
  }
`;

export const createSubcription = gql`
  mutation suscriptionRequest($user_id: ID!, $pack_id: String!, $message: String!) {
    suscriptionRequest(user_id: $user_id, pack_id: $pack_id, message: $message) {
      _id
    }
  }
`;

export const cancelNewsletter = gql`
  mutation cancelNewsletter($email: String!) {
    cancelNewsletter(email: $email) {
      OK
      message
    }
  }
`;

export const subscribeToBook = gql`
  mutation optInSuscription($_id: ID!, $email: String!) {
    optInSuscription(_id: $_id, email: $email) {
      OK
    }
  }
`;
